x<template>
  <v-container
    id="login-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            rounded
            max-width="700"
            class="mx-auto"
            color="primary"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  {{ appName }}
                </div>
              </div>
            </template>

            <v-card-text class="text-center">
              <div class="text-center font-weight-light">
                {{ $t('newPassword.title') }}
              </div>

              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="password"
                        rules="required|min:8"
                        :name="$t('password').toLowerCase()"
                      >
                        <v-text-field
                          v-model="form.password"
                          :error-messages="errors"
                          color="primary"
                          type="password"
                          :placeholder="$t('password')"
                          prepend-icon="mdi-account-box"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|confirmed:password"
                        :name="$t('confirmPassword').toLowerCase()"
                      >
                        <v-text-field
                          v-model="form.password_confirmation"
                          :error-messages="errors"
                          color="primary"
                          type="password"
                          :placeholder="$t('confirmPassword')"
                          prepend-icon="mdi-account-box"
                          validate-on-blur
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-space-around mb-6">
                    <router-link
                      to="/login"
                      class="font-weight-bold text-decoration-none"
                    >
                      {{ $t('newPassword.alreadyHave') }}
                    </router-link>
                  </div>

                  <SubmitButton
                    :label="$t('submit')"
                    type="submit"
                  />
                </form>
              </validation-observer>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import serverError from '../mixins/serverError';
  export default {
    name: 'ResetPasswordView',

    mixins: [serverError],

    data: () => ({
      form: {
        password: null,
        password_confirmation: null,
        token: null,
        email: null,
      },
    }),

    computed: {
      ...mapGetters({
        accountCreationEnabled: 'app/accountCreationEnabled',
        processing: 'global/getProcessing',
        appName: 'app/appName',
      }),
    },

    created() {
      const { email, token } = this.$route.query;
      this.form = {
        ...this.form,
        email,
        token,
      };
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { password, password_confirmation, email, token } = this.form
          const payload = { password, password_confirmation, email, token };
          this.$store.dispatch('auth/resetPassword', payload)
            .then(response => {
              this.$toasted.success(this.$t('newPassword.success'));
              this.$router.push('/login');
            })
            .catch(error => {
              this.displayErrors(error);
            });
        }
      },
    },
  }
</script>
